import React, { Fragment, useEffect, useState } from 'react'
import Logo from 'public/images/layout/footer_logo.png'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Listbox, Transition } from '@headlessui/react'
import { useTranslation } from 'next-i18next'
import Inquiry from '@src/layouts/footer/inquiry/Inquiry'
import * as S from './NewFooter.styled'

const NewFooter = () => {
  const router = useRouter()
  const { t } = useTranslation('layout')
  const SiteType = [{ id: 1, name: `${t('footer.이엘일렉트릭')}`, unavailable: false }]
  const [csEmail, setCsEmail] = useState('')
  const [saleEmail, setSaleEmail] = useState('')
  const selectedSite = {
    id: 0,
    name: `${t('footer.위브이')}`,
    unavailable: false,
  }

  useEffect(() => {
    const generateEmail = (firstEmail: string) => {
      const email = firstEmail + '@' + 'el-electric.co.kr'
      return email
    }
    setCsEmail(generateEmail('cs'))
    setSaleEmail(generateEmail('sales'))
  }, [])

  return (
    <>
      <S.Layout>
        <Inquiry />
        <div className="footerContent">
          <S.ContentLeftBox>
            <Image src={Logo} alt="이엘 일렉트릭 주식회사 로고" />
            <div>
              <p>INFORMATION</p>
              <ul>
                <li>
                  {t('footer.상호')} <span>|</span> {t('footer.대표이사')}
                </li>
                <li>
                  {t('footer.사업자번호')} : 459-86-01452 <span>|</span> {t('footer.통신판매번호')}
                </li>
                <li>{t('footer.본사')}</li>
                <li>{t('footer.1공장')}</li>
                <li>{t('footer.2공장')}</li>
              </ul>
            </div>
            <div>
              <p>CONTACT</p>
              <ul>
                <li>
                  {' '}
                  {t('footer.영업부서')} :{' '}
                  <a href={'mailto:' + saleEmail} className="font-extrabold">
                    {saleEmail}
                  </a>
                </li>
                <li>
                  {t('footer.고객센터')} :{' '}
                  <a href="tel:1600-0274" className="font-extrabold">
                    1600-0274
                  </a>{' '}
                  ㅣ{' '}
                  <a href="http://pf.kakao.com/_DBzcb" target="_blank" className="font-extrabold">
                    {t('footer.카카오톡')} @WEV(위브이)
                  </a>
                </li>
                <li>
                  {' '}
                  {t('footer.고객지원')} :{' '}
                  <a href={'mailto:' + csEmail} className="font-extrabold">
                    {csEmail}
                  </a>
                </li>
              </ul>
            </div>
          </S.ContentLeftBox>
          <S.ContentRightBox>
            <div>
              <div className="w-[195px]">
                <Listbox value={selectedSite}>
                  <div className="relative w-full mb-[30px] border-b  border-[#a9a9a9] ">
                    <Listbox.Button className="text-[#ffffff] relative w-full hover:cursor-pointer pr-10">
                      <span className="block truncate font-Orbitron text-sm text-left">{selectedSite.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full flex flex-col overflow-auto bg-[#ffffff] rounded-md text-fontblue py-1 border border-gray-400 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {SiteType.map((item, itemIdx) => (
                          <Listbox.Option
                            key={itemIdx}
                            className={({ active }) =>
                              `text-center relative cursor-pointer select-none py-2 pl-2 pr-2 text-[#2d2d2d]`
                            }
                            value={item}
                            onClick={() => window.open('http://el-electric.co.kr/')}
                          >
                            {({ selected }) => (
                              <>
                                <span className={`block truncate ${selected ? 'font-extrabold' : 'font-semibold'}`}>
                                  {item.name}
                                </span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              <div>
                <Image src="/images/layout/footer_badge1.png" width={90} height={64} quality={100} alt="인증 배지" />
                <Image src="/images/layout/footer_badge2.png" width={96} height={40} quality={100} alt="인증 배지" />
              </div>
            </div>
            <div>
              <div>
                <div onClick={() => router.push('/terms/use_policy')} className="hover:underline">
                  {t('footer.이용약관')}
                </div>
                <div onClick={() => router.push('/terms/privacy')} className="hover:underline">
                  {t('footer.개인정보처리방침')}
                </div>
              </div>

              <div>
                <Image
                  src="/images/layout/footer_instargram.png"
                  onClick={() => window.open('https://www.instagram.com/wev_charger', '_blank')}
                  alt="위브이::WEV 서비스 인스타그램 링크"
                  width={23}
                  height={22}
                />
                <Image
                  src="/images/layout/footer_naver.png"
                  onClick={() => window.open('https://blog.naver.com/wev-charger', '_blank')}
                  alt="위브이::WEV 서비스 블로그 링크"
                  width={23}
                  height={22}
                />
                <Image
                  src="/images/layout/footer_youtube.png"
                  onClick={() => window.open('https://www.youtube.com/@wevcharger', '_blank')}
                  alt="위브이::WEV 서비스 유튜브 링크"
                  width={34}
                  height={22}
                />
              </div>
            </div>
          </S.ContentRightBox>
        </div>
        <div className="text-center text-[#A9A9A9]">Copyright © EL ELECTRIC Corp. All rights reserved.</div>
      </S.Layout>
    </>
  )
}

export default NewFooter
