import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash/throttle'

export const useQuickMenuActive = () => {
  const [isAtTop, setIsAtTop] = useState(true)
  const [isActive, setIsActive] = useState(false)

  const handleScroll = () => {
    setIsActive(false) // 스크롤 동작 시 active off
    const sy = window.scrollY
    setIsAtTop(sy === 0)
  }

  const throttledHandleScroll = throttle(handleScroll, 200)

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll)

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  return {
    isAtTop,
    setIsActive,
    isActive,
  }
}
