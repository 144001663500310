import { IBlockMemberParam, IEditMemberRoleParam, IMemberSearchParam } from '@src/type/management/memberMain'
import {
  IMemberActiveLogParams,
  IMemberChargingHistoryParams,
  IMemberLoginLogParams,
  IMemberPaymentParams,
  IMemberPointHistoryParams,
  IMemberSelectParams,
  ISetNewPasswordCodeParams,
} from '@src/type/management/selectMember'
import instance from '../axios'

export const managementMemberApis = {
  getMemberList: (data: IMemberSearchParam) =>
    instance.get(
      `/management/member/list?p=${data.page}&list_num=${data.list}` +
        `${data.query ? `&query=${data.query}` : ''}` +
        `${data.policy1 ? `&policy1=${data.policy1}` : ''}` +
        `${data.policy2 ? `&policy2=${data.policy2}` : ''}` +
        `${data.category ? `&category=${data.category}` : ''}` +
        `${data.role ? `&role=${data.role}` : ''}` +
        `${data.suspend ? `&suspend=${data.suspend}` : ''}`,
    ),
  getMemberSelect: (id: number) => instance.get(`/management/member/select?id=${id}`),
  getMemberSelectV2: (data: IMemberSelectParams) =>
    instance.get(`/management/member/select-v2?id=${data.id}&list_num=${data.list}&p=${data.page}`),
  getWithDrawList: (page: number) => instance.get(`/management/member/withdraw?p=${page}`),
  editMemberRole: (data: IEditMemberRoleParam[]) => instance.put('/management/member/set-role', data),
  blockMember: (data: IBlockMemberParam[]) => instance.put('/management/member/set-suspend', data),
  getMemberPayment: (data: IMemberPaymentParams) =>
    instance.get(
      `/management/member/payment?member_id=${data.id}&list_num=10&p=${data.page}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}`,
    ),
  getMemberChargingHistory: (data: IMemberChargingHistoryParams) =>
    instance.get(
      `/management/member/charging-history?member_id=${data.id}&list_num=10&p=${data.page}` +
        `${data.category ? `&category=${data.category}` : ''}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}` +
        `${data.tid ? `&transaction_id=${data.tid}` : ''}` +
        `${data.option ? `&roaming=false` : '&roaming=true'}`,
    ),
  setNewPasswordCode: (data: ISetNewPasswordCodeParams) => instance.post(`/management/member/new-password-code`, data),
  getMemberPointHistory: (data: IMemberPointHistoryParams) =>
    instance.get(
      `/management/member/point-history?member_id=${data.id}&list_num=${data.list}&p=${data.page}` +
        `${data.category ? `&category=${data.category}` : ''}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}`,
    ),
  getMemberActiveLog: (data: IMemberActiveLogParams) =>
    instance.get(
      `/management/member/activity-log?member_id=${data.id}&list_num=${data.list}&p=${data.page}&is_user=${data.isUser}`,
    ),
  getMemberSelectActiveLog: (data: number) => instance.get(`/management/member/select-activity-log?id=${data}`),
  getMemberPersonalizeSet: (data: number) => instance.get(`/management/member/personalize?member_id=${data}`),
  getMemberLoginLog: (data: IMemberLoginLogParams) =>
    instance.get(
      `/management/member/login-log?list_num=${data.list}&p=${data.page}` +
        `${data.memberId ? `&member_id=${data.memberId}` : ''}` +
        `${data.result ? `&result=1` : `${data.result === 0 ? '&result=0' : ''}`}`,
    ),
  roamingClaimCharge: (memberId: number, data: { roamingChargingHistoryId: number }) =>
    instance.post(`/management/member/settlement-roaming?memberId=${memberId}`, data),
}
