import Link from 'next/link'
import { setIsHoverHeader } from '@src/store/modules/commons'
import { useEffect, useState } from 'react'
import { useResponsive } from '@src/hooks/useResponsive'
import { useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { useRouter } from 'next/router'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import { accountApis } from '@src/features/account/api'
import secureLocalStorage from 'react-secure-storage'
import Cookies from 'universal-cookie'
import { toast } from 'react-toastify'
import { Person_F, Toast_Warning } from '@src/assets/icons'
import { routeBgColorCheck } from '@src/utils/routeBgColorCheck'
import * as S from './Header.styled'

const AuthMenu = () => {
  const router = useRouter()
  const cookies = new Cookies()
  const { t } = useTranslation('layout')
  const { isTablet } = useResponsive(1280)
  const isHoverHeader = useAppSelector((state) => state.commons.isHoverHeader)
  const hasAccessToken = checkAccessToken()

  const logout = async () => {
    try {
      await accountApis.logout().then((res) => {
        if (res.data.result_code === 'OK') {
          router.replace('/login')
          secureLocalStorage.clear()
          cookies.remove('Wev_accessToken', { path: '/' })
          cookies.remove('Wev_refreshToken', { path: '/' })
        }
      })
    } catch (err: any) {
      toast.dark(err.response.data.result_message || '오류가 발생했습니다. 잠시 후 다시 시도해주세요.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
        icon: <Toast_Warning />,
      })
    }
  }

  return (
    <S.AuthMenu
      id="auth_menu"
      isTablet={isTablet}
      className="flex items-center"
      $textColor={routeBgColorCheck(router.asPath)}
      $isHeaderHovered={Boolean(isHoverHeader)}
    >
      {!hasAccessToken ? (
        <>
          <Link href={'/login'}>{`${t('header.로그인')}`}</Link>
          <span>&nbsp;/&nbsp;</span>
          <Link href={'/sign_up'} className="pr-[3px]">{`${t('header.회원가입')}`}</Link>
        </>
      ) : isTablet ? (
        <button onClick={() => logout()}>
          <p>로그아웃</p>
        </button>
      ) : (
        <button onClick={() => router.push('/user/my_info')} className="auth_user_btn">
          <Person_F
            fill={Boolean(isHoverHeader) ? '#1E1E1E' : routeBgColorCheck(router.asPath)}
            width={40}
            height={40}
          />
        </button>
      )}
    </S.AuthMenu>
  )
}

export default AuthMenu
