import { UrlObject } from 'url'

export interface MapItem {
  label: string
  href: UrlObject | string
}

interface WevSitemap {
  [key: string]: MapItem[]
}

type DataKey = {
  [key: string]: string
}

export const WEV_SITEMAP_KR: WevSitemap = {
  wev: [
    { label: 'WEV 소개', href: '/wev' },

    { label: 'BI', href: '/wev/BI' },
  ],
  service: [
    { label: 'APP', href: '/wev/service/app' },
    //  * no margin
    { label: '멤버십카드', href: '/wev/service/membership_card' },
    //  * no margin
    { label: '충전 요금', href: '/wev/service/fee' },

    { label: 'WEV 스테이션', href: '/wev/service/station' },

    // { label: '비즈니스', href: '/business/about' },
  ],
  products: [{ label: '전기차 충전기', href: '/wev/product' }],

  installation: [{ label: '충전기 설치 신청', href: '/wev/install_consult' }],
  partnership: [
    { label: '운영서비스', href: '/wev/partnership' },

    { label: '사업 제휴 신청', href: '/wev/partnership/consult' },
  ],
  newsletter: [
    { label: '공지사항', href: '/customer/notice' },

    { label: '이벤트', href: '/customer/event' },

    { label: 'FAQ', href: '/customer/faq' },

    { label: '뉴스', href: '/customer/news' },
  ],
  myPage: [
    // { label: '홈', href: '/' },
    { label: '내 정보', href: '/user/my_info' },
    { label: '멤버십', href: '/user/membership' },
    { label: '충전내역', href: '/user/charge_history' },
    { label: '결제내역', href: '/user/payment_history' },
    { label: '1:1 문의', href: '/user/inquiry' },
    { label: '알림 설정', href: '/user/notification' },
    { label: '약관 관리', href: '/user/terms' },
  ],
}
export const WEV_SITEMAP_EN: WevSitemap = {
  wev: [
    { label: 'WEV Introduction', href: '/wev' },

    { label: 'BI', href: '/wev/BI' },
  ],
  service: [
    { label: 'APP', href: '/wev/service/app' },
    //  * no margin
    { label: 'Membership Card', href: '/wev/service/membership_card' },
    //  * no margin
    { label: 'Charging Fee', href: '/wev/service/fee' },

    { label: 'WEV Station', href: '/wev/service/station' },

    { label: 'Business', href: '/business/about' },
  ],
  products: [{ label: 'Charger', href: '/wev/product' }],

  installation: [{ label: 'Charger Installation Application', href: '/wev/install_consult' }],
  partnership: [
    { label: 'Operation Service', href: '/wev/partnership' },

    { label: 'Business Partnership Application', href: '/wev/partnership/consult' },
  ],
  newsletter: [
    { label: 'Notice', href: '/customer/notice' },

    { label: 'Events', href: '/customer/event' },

    { label: 'FAQ', href: '/customer/faq' },

    { label: 'News', href: '/customer/news' },
  ],
  myPage: [
    // { label: 'Home', href: '/' },
    { label: 'My Info', href: '/user/my_info' },
    { label: 'Membership', href: '/user/membership' },
    { label: 'Charge History', href: '/user/charge_history' },
    { label: 'Payment History', href: '/user/payment_history' },
    { label: '1:1 Inquiry', href: '/user/inquiry' },
    { label: 'Notification Settings', href: '/user/notification' },
    { label: 'Terms Management', href: '/user/terms' },
  ],
}
export const WEV_SITEMAP_JP: WevSitemap = {
  wev: [
    { label: 'WEV 紹介', href: '/wev' },

    { label: 'BI', href: '/wev/BI' },
  ],
  service: [
    { label: 'アプリ', href: '/wev/service/app' },
    //  * no margin
    { label: 'メンバーシップ', href: '/wev/service/membership_card' },
    //  * no margin
    { label: '充電料金', href: '/wev/service/fee' },

    { label: 'WEV ステーション', href: '/wev/service/station' },

    { label: 'ビジネス', href: '/business/about' },
  ],
  products: [{ label: 'EV充電器', href: '/wev/product' }],

  installation: [{ label: '充電器設置申請', href: '/wev/install_consult' }],
  partnership: [
    { label: '運営サービス', href: '/wev/partnership' },

    { label: '事業提携申請', href: '/wev/partnership/consult' },
  ],
  newsletter: [
    { label: 'お知らせ', href: '/customer/notice' },

    { label: 'イベント', href: '/customer/event' },

    { label: 'FAQ', href: '/customer/faq' },

    { label: 'ニュース', href: '/customer/news' },
  ],
  myPage: [
    // { label: 'ホーム', href: '/' },
    { label: 'マイ情報', href: '/user/my_info' },
    { label: 'チャージ履歴', href: '/user/charge_history' },
    { label: '決済履歴', href: '/user/payment_history' },
    { label: '1:1 お問い合わせ', href: '/user/inquiry' },
    { label: '通知設定', href: '/user/notification' },
    { label: '規約管理', href: '/user/terms' },
  ],
}

export const WEV_SITEMAP_CATEGORY_KEYS_KR: DataKey = {
  wev: 'WEV',
  service: 'WEV서비스',
  products: '제품',
  installation: '설치 신청',
  partnership: '파트너십',
  newsletter: 'WEV소식',
  myPage: '마이페이지',
}

export const WEV_SITEMAP_CATEGORY_KEYS_EN: DataKey = {
  wev: 'WEV',
  service: 'Service',
  products: 'Product',
  installation: 'Installation',
  partnership: 'Partnership',
  newsletter: 'WEV News',
  myPage: 'My Page',
}

export const WEV_SITEMAP_CATEGORY_KEYS_JP: DataKey = {
  wev: 'WEV',
  service: 'WEV サービス',
  products: '製品',
  installation: '設置申請',
  partnership: 'パートナーシップ',
  newsletter: 'WEV ニュース',
  myPage: 'マイページ',
}
