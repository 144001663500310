import styled from 'styled-components'

export const Layout = styled.div`
  z-index: 15;
  padding: 3rem 0;
  background-color: #201f23;
  border-bottom: 1px solid #ddd;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 20px 0px;
  }

  & > .MuiContainer-root {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    padding: 0 4rem;

    @media ${({ theme }) => theme.device.pc} {
      flex-direction: column;
      text-align: center;
      padding: 0 2rem;

      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      & > p {
        ${({ theme }) => theme.wev_font.Bold_30}
      }
    }
    @media ${({ theme }) => theme.device.mobile} {
      gap: 0px;
    }
  }

  & p {
    background: linear-gradient(107deg, #2beede 23.19%, #583eee 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${({ theme }) => theme.wev_font.sBold_28}
  }

  & a {
    background: linear-gradient(#38373a, #38373a) padding-box, linear-gradient(to right, #2beede, #583eee) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: #201f23;
  }
`
