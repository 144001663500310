import styled from 'styled-components'

export const Layout = styled.footer`
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 0px 0px 70px 0px;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0%;
  background-color: #201f23;
  color: white;
  @media ${({ theme }) => theme.device.pc} {
    height: 100%;
  }
  .footerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 20px;
    height: 100%;
    max-width: 1280px;
    @media ${({ theme }) => theme.device.pc} {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: 100%;
    }
  }
`
export const ContentLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > p {
      ${({ theme }) => theme.wev_font.sBold_18}
    }
    > ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: ${({ theme }) => theme.wev_color.hint};
    }
    > ul > li {
      margin-bottom: 10px;
      ${({ theme }) => theme.wev_font.Regular_14}
    }
    > ul > li > span {
      margin: 0px 20px;
      font-weight: 700;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    gap: 20px;
    > img {
      margin-bottom: 30px;
    }
  }
`

export const ContentRightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 230px;
  color: ${({ theme }) => theme.wev_color.hint};
  @media ${({ theme }) => theme.device.pc} {
    width: 100%;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
  }
  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    > select {
      margin-bottom: 50px;
      min-width: 165px;
      background-color: #201f23;
      border: none;
      border-bottom: 1px solid #a9a9a9;
      outline: none;
      :focus {
        box-shadow: none;
      }
    }
    > div {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
  > div:last-child {
    display: flex;
    align-items: center;
    gap: 15px;
    ${({ theme }) => theme.wev_font.Regular_14}
    > div {
      cursor: pointer;
      display: flex;
      gap: 20px;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 30px;
    > div {
      display: flex;
      flex-direction: column;
    }
    > div:last-child {
      display: flex;
      align-items: center;
      gap: 15px;
      ${({ theme }) => theme.wev_font.Regular_12}
    }
  }
`
