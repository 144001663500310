import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isHoverHeader: null,
  isRightSidebarOpen: false,
  scrollSidebarIconColor: '#fff',
  isSitemapOpen: false,
  activeMobileSitemap: null,
}
export const commonsSlice = createSlice({
  name: 'commonsSlice',
  initialState,
  reducers: {
    setIsHoverHeader: (state, action) => {
      state.isHoverHeader = action.payload
    },
    setIsRightSidebarOpen: (state, action) => {
      state.isRightSidebarOpen = action.payload
    },
    setScrollSidebarIconColor: (state, action) => {
      state.scrollSidebarIconColor = action.payload
    },
    setIsSitemapOpen: (state, action) => {
      state.isSitemapOpen = action.payload
    },
    setActiveMobileSitemap: (state, action) => {
      state.activeMobileSitemap = action.payload
    },
  },
})
export const { setIsHoverHeader, setIsSitemapOpen, setScrollSidebarIconColor, setActiveMobileSitemap } =
  commonsSlice.actions
export default commonsSlice.reducer
