import { wev_color } from './property'
import { wev_font, font } from './property'
import { device } from './property'
import { clamp } from './property'
export const theme = {
  wev_color,
  wev_font,
  font, // 반응형 폰트 크기 수정을 위함
  device,
  clamp,
}
