import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { nanoid } from '@reduxjs/toolkit'
import { WEV_SITEMAP_KR } from '@src/constant/sitemap'
import * as S from './UserSubHeader.styled'

const UserSubHeader = () => {
  const router = useRouter()

  return (
    <S.SubHeader>
      <ul>
        {WEV_SITEMAP_KR.myPage.map((el) => (
          <S.Item isActiveStatus={router.asPath.startsWith(el.href as string)} key={nanoid()}>
            <Link href={el.href}>{el.label}</Link>
          </S.Item>
        ))}
      </ul>
    </S.SubHeader>
  )
}

export default UserSubHeader
