import styled from 'styled-components'

export const SubHeader = styled.div`
  width: 100%;
  height: 46px;
  overflow-x: scroll;
  border-bottom: 1px solid ${({ theme }) => theme.wev_color.outline};
  ::-webkit-scrollbar {
    display: none;
  }
  > ul {
    width: fit-content;
    padding: 0px 20px;
    display: flex;
    gap: 20px;
  }
`

export const Item = styled.li<{ isActiveStatus: boolean }>`
  ${({ theme }) => theme.wev_font.Regular_16}
  color: ${({ theme, isActiveStatus }) => (isActiveStatus ? theme.wev_color.primary : theme.wev_color.text)};
  padding: 15px 0px;
  white-space: nowrap;
  text-align: center;
`
