import React, { ReactNode } from 'react'
import styled from 'styled-components'

const CustomTooltip = ({
  children,
  message,
  type = 'right',
}: {
  children: ReactNode
  message: string
  type?: 'right' | 'bottom' | 'top'
}) => {
  if (type === 'right') {
    return (
      <Container>
        {children}
        <div className="tooltip">{message}</div>
      </Container>
    )
  } else if (type === 'top') {
    return (
      <BottomContainer>
        {children}
        <div className="tooltip">{message}</div>
      </BottomContainer>
    )
  } else {
    return (
      <TopContainer>
        {children}
        <div className="tooltip">{message}</div>
      </TopContainer>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }

  .tooltip {
    white-space: pre-line;
    display: none;
    position: absolute;
    bottom: 50%;
    right: -9px;
    background-color: #000000cc;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    height: auto;
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 11px;
    width: max-content;
    z-index: 100;
    transform: translate(100%, 50%);
  }

  /* .tooltip::after {
    border-color: #000000cc transparent;
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: '';
    position: absolute;
    display: block;
    left: -9px;
    top: 40%;
    transform: translate(100%, -50%);
    width: 0;
    z-index: 1;
    transform: rotate(-90deg);
  } */
`
const BottomContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }

  .tooltip {
    white-space: pre-line;
    display: none;
    position: absolute;
    top: -100%;
    background-color: #000000cc;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    height: auto;
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 11px;
    width: max-content;
    z-index: 100;
  }
`
const TopContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }

  .tooltip {
    white-space: pre-line;
    display: none;
    position: absolute;
    bottom: -80%;

    background-color: #000000cc;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    height: auto;
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 11px;
    width: max-content;
    z-index: 100;
  }
`
export default CustomTooltip
