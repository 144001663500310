import styled, { css } from 'styled-components'

export const SiteMap = styled.aside<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.wev_color.primary};
  z-index: 12;
  > button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: translateX(0);
    `}
`

export const SiteMapContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  width: 100%;
  max-width: 1280px;
  height: fit-content;
  color: white;
  > h1 {
    margin-left: 70px;
    ${({ theme }) => theme.wev_font.Bold_48};
  }
  > div {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0px 70px;
    text-align: center;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 30px;
      > p {
        ${({ theme }) => theme.wev_font.Bold_24};
        white-space: nowrap;
      }
      > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 30px;
        text-align: center;
        ${({ theme }) => theme.wev_font.Regular_20};
        .sitemap_sub_menu {
          display: flex;
          flex-direction: column;
          gap: 15px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`
export const MobileBackDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
`

export const MobileSiteMap = styled.aside<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 65%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.wev_color.primary};
  z-index: 12;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: translateX(0);
    `}

  .mobile_sitemap_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #ffffff40;
    padding: 0px 20px;
    height: 54px;
  }
`

export const AccordionItem = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff40;
  padding: 0px 20px;
  width: 100%;
  height: 49px;
  ${({ theme }) => theme.wev_font.sBold_16};
  color: ${({ theme }) => theme.wev_color.white};
  cursor: pointer;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      > svg {
        transform: rotate(180deg);
      }
    `}
`

export const AccordionItemContent = styled.div`
  border-bottom: 1px solid #ffffff40;
  padding: 20px;
  ${({ theme }) => theme.wev_font.Medium_14};
  color: #ffffff;
  background-color: #422aca;
  > ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .mobile_sitemap_sub_menu {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px 10px;
        ${({ theme }) => theme.wev_font.Regular_12};
      }
    }
  }
`

export const MobileSubMenu = styled.div`
  /* position: absolute; */
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`
