import styled from 'styled-components'

export const SubMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media (min-width: 768px) {
    margin-right: 0px;
  }
`
export const SubMenuSlice = styled.div<{ $bg_color: string }>`
  width: 1px;
  height: 30px;
  background-color: ${({ $bg_color }) => $bg_color};
`
