import React from 'react'

const Tooltip = ({ title, content }: { title?: string; content: string }) => {
  return (
    <div className="relative py-3 sm:max-w-xl sm:mx-auto">
      <div className="group relative inline-block">
        <div className="flex whitespace-nowrap">
          <div className="text-red-700 font-semibold">{title}</div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="ml-1 w-5 h-5 text-gray-400 hover:cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
        </div>
        <div className="border-light text-body-color absolute left-full top-1/2 z-20 ml-3 -translate-y-1/2 whitespace-nowrap rounded border bg-white py-[6px] px-4 text-sm opacity-0 group-hover:opacity-100 pointer-events-none">
          <span className="border-light absolute -left-1 top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-r-sm border-b border-l bg-white"></span>
          {content}
        </div>
      </div>
    </div>
  )
}

export default Tooltip
