'use client'

import React, { Fragment, useEffect, useState } from 'react'
import Link from 'next/link'
import { Close, Keyboard_Arrow_Down, Language } from '@src/assets/icons'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { setActiveMobileSitemap, setIsSitemapOpen } from '@src/store/modules/commons'
import { useResponsive } from '@src/hooks/useResponsive'
import { useRouter } from 'next/router'
import {
  WEV_SITEMAP_CATEGORY_KEYS_EN,
  WEV_SITEMAP_CATEGORY_KEYS_JP,
  WEV_SITEMAP_CATEGORY_KEYS_KR,
  WEV_SITEMAP_EN,
  WEV_SITEMAP_JP,
  WEV_SITEMAP_KR,
} from '@src/constant/sitemap'
import AuthMenu from '../header/AuthMenu'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import LanguageSettings from '../header/subMenu/languageSettings/LanguageSettings'
import * as S from './SiteMap.styled'

const SiteMap = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const activeMobileSitemap = useAppSelector((state) => state.commons.activeMobileSitemap)
  const isSitemapOpen = useAppSelector((state) => state.commons.isSitemapOpen)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const locale = router.locale || 'ko'
  const hasAccessToken = checkAccessToken()
  const { isTablet } = useResponsive(1280)

  const handleClickLanguageSettings = (e: null | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e === null) {
      setAnchorEl(null)
      return
    }
    setAnchorEl(e.currentTarget)
  }

  const getLocaleData = (language: string) => {
    switch (language) {
      case 'en':
        return {
          data: WEV_SITEMAP_EN,
          data_key: WEV_SITEMAP_CATEGORY_KEYS_EN,
        }
      case 'jp':
        return {
          data: WEV_SITEMAP_JP,
          data_key: WEV_SITEMAP_CATEGORY_KEYS_JP,
        }
      default: // 한국어(kr)를 기본으로 설정
        return {
          data: WEV_SITEMAP_KR,
          data_key: WEV_SITEMAP_CATEGORY_KEYS_KR,
        }
    }
  }
  const localeData = getLocaleData(locale)

  const toggleAccordion = (menu: string) => {
    if (menu === activeMobileSitemap) dispatch(setActiveMobileSitemap(null))
    else {
      dispatch(setActiveMobileSitemap(menu))
    }
  }

  useEffect(() => {
    if (isSitemapOpen) {
      document.body.setAttribute('class', 'noScroll')
    } else {
      document.body?.removeAttribute('class')
    }

    return () => {
      document.body?.removeAttribute('class')
    }
  }, [isSitemapOpen, activeMobileSitemap])

  return (
    <>
      {!isTablet ? (
        <S.SiteMap $isOpen={isSitemapOpen}>
          <button onClick={() => dispatch(setIsSitemapOpen(false))}>
            <Close fill="#ffffff" width={40} height={40} stroke="#ffffff" strokeWidth="1" />
          </button>

          <S.SiteMapContent>
            <h1>WEV SITEMAP</h1>
            <div>
              {Object.keys(localeData.data)
                .filter((category) => category !== 'myPage')
                .map((el) => (
                  <div key={el}>
                    <p>{localeData.data_key[el]}</p>
                    <ul>
                      {localeData.data[el].map((el) => (
                        <div className="sitemap_sub_menu" key={el.label}>
                          <Link href={el.href} onClick={() => dispatch(setIsSitemapOpen(false))}>
                            <li>{el.label}</li>
                          </Link>
                        </div>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </S.SiteMapContent>
        </S.SiteMap>
      ) : (
        <>
          {isSitemapOpen && <S.MobileBackDrop onClick={() => setIsSitemapOpen(false)} />}
          <S.MobileSiteMap $isOpen={isSitemapOpen}>
            <div className="mobile_sitemap_header">
              <button onClick={() => dispatch(setIsSitemapOpen(false))}>
                <Close fill="#ffffff" width={24} height={24} />
              </button>
            </div>
            <div className="mobile_sitemap_content">
              {(hasAccessToken
                ? Object.keys(localeData.data)
                : Object.keys(localeData.data).filter((category) => category !== 'myPage')
              ).map((el) => (
                <Fragment key={el}>
                  <S.AccordionItem onClick={() => toggleAccordion(el)} $isOpen={el === activeMobileSitemap}>
                    {localeData.data_key[el]} <Keyboard_Arrow_Down fill={'#ffffff'} width={20} height={20} />
                  </S.AccordionItem>
                  {el === activeMobileSitemap && (
                    <S.AccordionItemContent>
                      <ul>
                        {localeData.data[el].map((el) => (
                          <div className="mobile_sitemap_sub_menu" key={el.label}>
                            <Link href={el.href} onClick={() => dispatch(setIsSitemapOpen(false))}>
                              <li>{el.label}</li>
                            </Link>
                          </div>
                        ))}
                      </ul>
                    </S.AccordionItemContent>
                  )}
                </Fragment>
              ))}
            </div>
            <S.MobileSubMenu>
              <AuthMenu />
              <button onClick={handleClickLanguageSettings}>
                <Language fill="#ffffff" width={24} height={24} />
              </button>
              <LanguageSettings
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                handleClose={() => handleClickLanguageSettings(null)}
              />
            </S.MobileSubMenu>
          </S.MobileSiteMap>
        </>
      )}
    </>
  )
}

export default SiteMap
